






















































































































































































import { defineComponent } from '@nuxtjs/composition-api'
import CAvatar from '~/components/shared/configurable/image/avatar/CAvatar.vue'
import MyClassifiedsLinks from '~/components/car/widgets/MyClassifiedsLinks.vue'
import NotificationCenterPopover from '~/components/shared/configurable/notification-center/NotificationCenterPopover.vue'
import { mapGetters, mapState } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { toCamelCase } from '~/utils/object'
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import FeedSideButton from '~/components/car/classifieds/index/feeds/sidebar/FeedSideButton.vue'
import FeedSidebarIconContainer from '~/components/car/classifieds/index/feeds/sidebar/FeedSidebarIconContainer.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { ciAngleDownLight } from '~/icons/source/solid/angle-down-light'
import { ciFolderOpen } from '~/icons/source/regular/folder-open'
import { ciEnvelope } from '~/icons/source/regular/envelope'
import { ciParking } from '~/icons/source/regular/parking'
import { ciBell } from '~/icons/source/regular/bell'
import { faClock, faHeart } from '@fortawesome/free-regular-svg-icons'
import { ciUserPlus } from '~/icons/source/regular/user-plus'
import { ciSignInAlt } from '~/icons/source/regular/sign-in-alt'
import RouteGuardService from '~/services/RouteGuardService'

interface Data {
  myClassifieds: object[]
  myClsfdsOpen: boolean
  parkingOpen: boolean
  userOpen: boolean
}
export default defineComponent({
  components: {
    FeedSidebarIconContainer,
    FeedSideButton,
    NotificationCenterPopover,
    MyClassifiedsLinks,
    CAvatar
  },
  async fetch() {
    if (!this.isAnon && !this.isAuditsUser) {
      await this.fetchMyClsfds()
    }
  },
  data(): Data {
    return {
      myClassifieds: [],
      myClsfdsOpen: false,
      parkingOpen: false,
      userOpen: false
    }
  },
  computed: {
    ...mapState(USER_NS, {
      username: 'name',
      avatar: 'avatar',
      unreadNotifications: 'unreadNotifications',
      msgCount: 'unreadMessages',
      parkingCount: 'unreadParkingNotifications'
    }),
    ...mapGetters(USER_NS, {
      isDealer: 'isOfTypeDealer',
      isAdmin: 'isAdmin',
      isGuest: 'isGuest',
      isAnon: 'isSingleOrAnon',
      isAuditsUser: 'isAuditsUser'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    ...mapDeps({
      legacyUrlService: LegacyUrlService,
      routeGuardService: RouteGuardService
    }),
    icons() {
      return {
        folder: ciFolderOpen,
        envelope: ciEnvelope,
        chevronDown: faChevronDown,
        chevronDownsm: ciAngleDownLight,
        parking: ciParking,
        register: ciUserPlus,
        login: ciSignInAlt,
        bell: ciBell,
        heart: faHeart,
        clock: faClock,
        list: faBars
      }
    },
    messagesLink() {
      if (this.isDealer || this.isAdmin || this.isGuest) {
        return this.$router.resolve({
          name: '__account_messages'
        }).href
      }

      return null
    },
    registerLink() {
      return this.legacyUrlService.getRegisterUrl({ 'from-index': 1 })
    },
    parkingLinks() {
      return [
        {
          label: this.$t('favorite classifieds'),
          url: '/parking/',
          icon: this.icons.heart,
          notifications: this.parkingCount || 0
        },
        {
          label: this.$t('favorite searches'),
          url: '/parking/searches/',
          icon: this.icons.heart
        },
        {
          label: this.$t('recent classifieds'),
          url: '/parking/recent/classifieds/',
          icon: this.icons.clock
        },
        {
          label: this.$t('recent searches'),
          url: '/parking/recent/searches/',
          icon: this.icons.clock
        },
        {
          label: this.$t('lists'),
          url: '/parking/lists/',
          icon: this.icons.list
        }
      ]
    },
    hasAccessToParking() {
      return this.routeGuardService.userHasAccessToParking()
    },
    hasAccessToMessages() {
      return this.routeGuardService.userCanReceiveMessages()
    },
    hasAccessToNotifications() {
      return this.routeGuardService.userHasAccessToNotifications()
    }
  },
  methods: {
    async fetchMyClsfds() {
      const { data } = await this.$axios.get('/api/user/classifieds/counts/')

      if (data.data) {
        this.myClassifieds = toCamelCase(data.data.states)
      }
    },
    toggleMyClassifieds() {
      this.myClsfdsOpen = !this.myClsfdsOpen
    },
    toggleParking() {
      this.parkingOpen = !this.parkingOpen
    },
    toggleUserOpen() {
      this.userOpen = !this.userOpen
      this.$emit('user-open', this.userOpen)
    },
    controlPanelClicked(e) {
      e.preventDefault()
      this.$analytics.recordEvent({
        namespace: 'n_index_control_panel_click',
        action: 'click'
      })
      window.location.href = '/account/'
    },
    myClassifiedClick(e, myclsfd) {
      e.preventDefault()
      this.$analytics.recordEvent({
        namespace: 'n_index_my_classifieds_click',
        action: 'click',
        label: myclsfd.label
      })

      window.location.href = myclsfd.seo_url
    },
    messagesClicked(e) {
      e.preventDefault()
      this.$analytics.recordEvent({
        namespace: 'n_index_messages_click',
        action: 'click'
      })

      window.location.href = this.messagesLink
    },
    registerClicked(e) {
      e.preventDefault()
      this.$analytics.recordEvent({
        namespace: 'n_index_register_click',
        action: 'click'
      })
      window.location.href = this.registerLink
    },
    loginClicked(e) {
      e.preventDefault()
      this.$analytics.recordEvent({
        namespace: 'n_index_login_click',
        action: 'click'
      })
      window.location.href = '/login/'
    },
    offerClicked(e) {
      e.preventDefault()
      this.$analytics.recordEvent({
        namespace: 'n_index_sidebar_offer_clicked',
        action: 'click'
      })
      window.location.href = this.legacyUrlService.offerBtnLink()
    },
    parkingLinkClicked(pl) {
      this.$analytics.recordEvent({
        namespace: 'n_index_parking_link_clicked',
        action: 'click',
        label: pl.label
      })
      window.location.href = pl.url
    }
  }
})
