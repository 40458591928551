















































































































































import {
  defineComponent,
  useFetch,
  useRouter,
  ref,
  set,
  onMounted,
  toRefs,
  PropType
} from '@nuxtjs/composition-api'
import { useDep } from '~/compositions/dependency-container'
import { useSnackbar } from '~/compositions/snackbar'
import { useLogger } from '~/compositions/logger'
import { useI18n } from '~/compositions/i18n'
import { handleError } from '~/utils/http'
import { ciTimes } from '~/icons/source/regular/times'
import HierarchicalCategoryService from '~/services/category/HierarchicalCategoryService'
import { HierarchicalCategory, CategoryId } from '~/models/category/types'
import { AxiosError } from 'axios'
import FeedSideButton from '~/components/car/classifieds/index/feeds/sidebar/FeedSideButton.vue'
import CCategoryIcon from '~/components/shared/configurable/category/CCategoryIcon.vue'
import { ciAngleDownLight } from '~/icons/source/solid/angle-down-light'
import useQuickSearchMenu from '~/compositions/quick-search-menu/quick-search-menu'
import FeedSidebarIconContainer from '~/components/car/classifieds/index/feeds/sidebar/FeedSidebarIconContainer.vue'
import { ciSearch } from '~/icons/source/duotone/search'
import CIcon from '~/components/shared/configurable/CIcon.vue'

export default defineComponent({
  components: {
    CCategoryIcon,
    FeedSideButton,
    CIcon,
    FeedSidebarIconContainer,
    CNewBadge: () =>
      import('~/components/shared/configurable/badge/CNewBadge.vue')
  },
  props: {
    isDrawer: {
      type: Boolean,
      default: true
    },
    selectedSearchbarCategory: {
      type: Number as PropType<CategoryId>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { topLevelCategories, parseCategory } = useQuickSearchMenu()
    const { selectedSearchbarCategory } = toRefs(props)

    onMounted(() => {
      const index =
        selectedSearchbarCategory.value === CategoryId.VEHICLES ? 0 : 1

      topLevelCategories.value.forEach((item, i) => {
        item.selected = false
        if (i !== index) item.hasOpenMenu = false
      })
      topLevelCategories.value[index].selected = true
      topLevelCategories.value[index].hasOpenMenu = true
      selectedTopLevelCategoryIndex.value = index
    })

    const hierarchicalCategoryService = useDep(HierarchicalCategoryService)
    const snackbar = useSnackbar()
    const logger = useLogger()
    const { t } = useI18n()
    const router = useRouter()

    const { fetch } = useFetch(
      async (): Promise<any> => {
        try {
          if (!categoryChangedInDrawer.value) {
            parentID.value = String(selectedSearchbarCategory.value)
          }
          // get subcategories menu
          const response = await hierarchicalCategoryService.getQuickSearchCategoryMap(
            parentID.value,
            Number(parentID.value),
            true
          )
          subTreeCategories.value = []
          Array.from(response).reduce((acc: any, cur: any) => {
            acc.push(cur[1])
            return acc
          }, subTreeCategories.value)

          topLevelCategories.value[
            selectedTopLevelCategoryIndex.value
          ].subCategories = subTreeCategories.value

          set(topLevelCategories.value, selectedTopLevelCategoryIndex.value, {
            ...topLevelCategories.value[selectedTopLevelCategoryIndex.value],
            subCategories: subTreeCategories.value
          })
        } catch (err) {
          handleError(err as AxiosError, snackbar, logger, t)
        }
      }
    )

    const parentID = ref(String(CategoryId.VEHICLES))
    const subTreeCategories = ref<HierarchicalCategory[]>([])
    const selectedTopLevelCategoryIndex = ref(0)
    const categoryChangedInDrawer = ref(false)

    const icons = {
      clear: ciTimes,
      downArrow: ciAngleDownLight,
      search: ciSearch
    }

    const getTopCategoryUrl = (id: string) => {
      if (id === String(CategoryId.JOBS)) {
        return router.resolve({
          path: '/jobs',
          query: { category: id }
        }).href
      }

      if (id === CategoryId.DEALS) {
        return router.resolve({
          path: '/deals'
        }).href
      }

      if (id === String(CategoryId.PLOT)) {
        return router.resolve({ path: '/plot/search' }).href
      }

      if (id === String(CategoryId.RENTALS)) {
        return router.resolve({ path: '/rentals/search/' }).href
      }

      if (String(CategoryId.WANTED_PARTS) === id) {
        return router.resolve({
          name: '__classifieds_search_wanted_parts'
        }).href
      }

      if (id === String(CategoryId.XYMA)) {
        return router.resolve({
          name: '__classifieds_quick_search',
          params: {
            parentId: id,
            root: parseCategory(id, 'id')
          }
        }).href
      }

      if (id === String(CategoryId.SERVICE)) {
        return router.resolve({ path: '/quick-search/service/' }).href
      }

      return undefined
    }

    const handleTopCategorySelection = (
      event: Event,
      id: string,
      index: number
    ) => {
      parentID.value = id
      categoryChangedInDrawer.value = true
      if (
        [
          CategoryId.XYMA,
          CategoryId.RENTALS,
          CategoryId.PLOT,
          CategoryId.DEALS,
          CategoryId.JOBS,
          CategoryId.SERVICE,
          CategoryId.WANTED_PARTS
        ]
          .map(c => String(c))
          .includes(id)
      ) {
        emit('menu-navigate', event, getTopCategoryUrl(id))
        return
      }

      topLevelCategories.value.forEach((item, i) => {
        item.selected = false
        if (i !== index) item.hasOpenMenu = false
      })
      topLevelCategories.value[index].selected = true
      topLevelCategories.value[index].hasOpenMenu = !topLevelCategories.value[
        index
      ].hasOpenMenu
      selectedTopLevelCategoryIndex.value = index

      fetch()
    }

    const getSubcategoryUrl = (id: string) => {
      return router.resolve({
        name: '__classifieds_quick_search',
        params: {
          parentId: parentID.value,
          root: parseCategory(parentID.value, 'id'),
          category: parseCategory(id, 'id')
        }
      }).href
    }

    return {
      icons,
      subTreeCategories,
      topLevelCategories,
      handleTopCategorySelection,
      getSubcategoryUrl,
      getTopCategoryUrl,
      CategoryId
    }
  }
})
